import cx from "classnames";
import * as React from "react";

import { StandardProps } from "../utils/element-type";
import { Post } from "../utils/gq-types";

import Card from "./Card";

interface HomePostListProps
  extends StandardProps<React.HtmlHTMLAttributes<HTMLDivElement>> {
  posts: Post[];
}

const HomePostList: React.FunctionComponent<HomePostListProps> = ({
  posts,
}) => {
  return (
    <div className={cx("grid", "grid-flow-row", "md:grid-cols-2", "gap-4")}>
      {posts.map(({ id, slug, title, featureImage, publishedAt }) => (
        <Card
          href={slug}
          key={id}
          title={title}
          subTitle={publishedAt}
          imageSrc={featureImage}
        />
      ))}
    </div>
  );
};

export default HomePostList;
