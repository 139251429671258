import cx from "classnames";
import * as React from "react";
import { graphql } from "gatsby";
import { view } from "ramda";

import { StandardProps } from "../utils/element-type";
import { GQData, GQEdges, Post } from "../utils/gq-types";
import listLensFactory from "../utils/list-lens-factory";

import OuterContainer from "../components/OuterContainer";
import PostComponent from "../components/Post";
import HomePostList from "../components/HomePostList";
import SocialHeader from "../components/SocialHeader";

interface FeaturedDataProps {
  featured: GQEdges<Post>;
  posts: GQEdges<Post>;
}

interface HomeProps
  extends StandardProps<React.HtmlHTMLAttributes<HTMLDivElement>>,
    GQData<FeaturedDataProps> {}

const featuredLens = listLensFactory<Post>();

const Home: React.FunctionComponent<HomeProps> = (props) => {
  const { data } = props;
  const featured: Post[] = view(featuredLens, data.featured);
  const posts: Post[] = view(featuredLens, data.posts);
  return (
    <>
      <SocialHeader post={featured[0]} isSinglePost={false} />
      {featured.map((post) => (
        <PostComponent {...post} key={post.id} shouldHaveLink={true} />
      ))}
      <OuterContainer className={"mt-8"}>
        <div
          className={cx(
            "flex",
            "flex-col",
            "justify-center",
            "items-center",
            "my-48"
          )}
        >
          <h1 className={cx("font-display", "text-5xl", "text-white")}>
            👋 Hello, I am Sylvester.
          </h1>
          <h2 className={cx("font-body", "text-xl", "text-white", "mt-4")}>
            I'm a hobbyist photographer based in Berlin, Germany.
          </h2>
        </div>
      </OuterContainer>
      <OuterContainer className={"mt-8"}>
        <HomePostList posts={posts} />
      </OuterContainer>
    </>
  );
};

export default Home;

export const query = graphql`
  query HomeQuery {
    featured: allGhostPost(
      sort: { fields: published_at, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          id
          slug
          title
          featureImage: feature_image
          publishedAt: published_at(fromNow: true)
          # tags {
          #   id
          #   name
          # }
        }
      }
    }
    posts: allGhostPost(sort: { fields: published_at, order: DESC }, skip: 1) {
      edges {
        node {
          id
          slug
          title
          featureImage: feature_image
          publishedAt: published_at(fromNow: true)
          # tags {
          #   id
          #   name
          # }
        }
      }
    }
  }
`;
