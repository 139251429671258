import cx from "classnames";
import * as React from "react";
import { Link } from "gatsby";

import { Props as ProgressiveImageProps } from "@sylvesteraswin/react-lazyload-image";

import { StandardProps } from "../utils/element-type";
import { makeSmallImage, getSourceSet } from "../utils/url-replace";

import CardSkeleton from "./CardSkeleton";
import EmptyComponent from "./EmptyComponent";

import styles from "./Card.module.css";

const TIMEOUT_CONFIG = { timeout: 100 };

const EMPTY_IMAGE =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGP6zwAAAgcBApocMXEAAAAASUVORK5CYII=";

export interface CardProps
  extends StandardProps<React.HtmlHTMLAttributes<HTMLDivElement>> {
  href?: string;
  imageSrc: string;
  title?: string;
  subTitle?: string;
  backgroundColor?: string;
  orientation?: "horizontal" | "vertical";
}

export type ImportBlockReturnType = {
  default: React.ElementType<ProgressiveImageProps>;
};

export const importBlock = async (): Promise<ImportBlockReturnType> => {
  const component = (await import(`@sylvesteraswin/react-lazyload-image`).then(
    (comp) => comp
  )) as ImportBlockReturnType;
  return component;
};

const Card: React.FunctionComponent<CardProps> = ({
  href,
  imageSrc,
  title,
  subTitle,
  backgroundColor,
  orientation = "horizontal",
}) => {
  const [imageComponent, setimageComponent] = React.useState<
    ImportBlockReturnType | undefined
  >(undefined);
  const [isHovered, setIsHovered] = React.useState(false);

  React.useEffect(() => {
    async function loadImageComponent({
      didTimeout,
    }: RequestIdleCallbackDeadline) {
      if (didTimeout) {
        window.requestIdleCallback(loadImageComponent, TIMEOUT_CONFIG);
      } else {
        const component = await importBlock();
        setimageComponent(component);
      }
    }
    if ("requestIdleCallback" in window) {
      window.requestIdleCallback(loadImageComponent, TIMEOUT_CONFIG);
    }
  }, [imageSrc]);

  if (!imageComponent) {
    return (
      <CardSkeleton
        style={{
          backgroundColor,
        }}
        orientation={orientation}
        disableTitleSection={!title || !subTitle}
      />
    );
  }
  const ProgressiveImage =
    (imageComponent && imageComponent.default) || EmptyComponent;
  const Wrapper = href ? Link : "div";
  const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (e) => {
    const el = e.currentTarget;
    const rect = el.getBoundingClientRect();
    setIsHovered(true);

    el.style.setProperty(
      "--x",
      `${e.clientX - (rect.left + Math.floor(rect.width / 2))}`
    );
    el.style.setProperty(
      "--y",
      `${e.clientY - (rect.top + Math.floor(rect.height / 2))}`
    );
  };
  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = (e) => {
    const el = e.currentTarget;
    el.style.setProperty("--x", `${0}`);
    el.style.setProperty("--y", `${0}`);

    setTimeout(() => {
      setIsHovered(false);
    }, 500);
  };
  return (
    <Wrapper
      to={href}
      className={cx("flex", "flex-col", "no-underline", "relative")}
    >
      <div
        className={cx(
          "relative",
          "pointer-events-auto",
          "select-none",
          {
            "pb-img-horizontal": orientation === "horizontal",
            "pb-img-vertical": orientation === "vertical",
          },
          styles.parallax
        )}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <ProgressiveImage
          src={makeSmallImage(imageSrc)}
          srcSetData={{
            srcSet: getSourceSet(imageSrc),
            sizes: "50vw",
          }}
          placeholder={""}
          rootMargin="0% 0% 0%"
        >
          {(loadedSrc, loading, srcSetData) => (
            <div
              className={cx(
                "absolute",
                "h-full",
                "w-full",
                "object-cover",
                "overflow-hidden",
                styles.parallaxImageWrapper
              )}
            >
              <div
                className={cx(
                  "absolute",
                  "h-full",
                  "w-full",
                  "bg-cover",
                  "object-cover",
                  "bg-no-repeat",
                  "bg-center",
                  "z-0"
                )}
                style={{
                  backgroundColor: !isHovered ? backgroundColor : "#000",
                }}
              />
              <img
                className={cx(
                  "absolute",
                  "h-full",
                  "w-full",
                  "object-cover",
                  "duration-300",
                  "transition-opacity",
                  "z-10",
                  "select-none",
                  styles.parallaxImage,
                  {
                    ["opacity-0"]: loading,
                  }
                )}
                src={loading ? EMPTY_IMAGE : loadedSrc}
                srcSet={loading ? undefined : srcSetData.srcSet}
                sizes={loading ? "50vw" : srcSetData.sizes}
                alt={title}
              />
            </div>
          )}
        </ProgressiveImage>
      </div>
      {title && subTitle && (
        <>
          <div
            className={cx(
              "absolute",
              "w-full",
              "h-full",
              "z-50",
              "flex",
              "flex-col",
              "justify-center",
              "items-center",
              "select-none",
              "pointer-events-none"
            )}
          >
            <h2
              className={cx(
                "font-display",
                "text-white",
                "text-3xl",
                "text-center"
              )}
            >
              {title}
            </h2>
            <h4 className={cx("text-white", "font-body", "text-xs")}>
              {subTitle}
            </h4>
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default Card;
