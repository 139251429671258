import cx from "classnames";
import * as React from "react";

import { StandardProps } from "../utils/element-type";
import { CardProps } from "./Card";

interface CardSkeletonProps
  extends StandardProps<React.HtmlHTMLAttributes<HTMLDivElement>>,
    Pick<CardProps, "orientation"> {
  disableTitleSection?: boolean;
}

const CardSkeleton: React.FunctionComponent<CardSkeletonProps> = ({
  disableTitleSection = false,
  style,
  orientation = "horizontal",
}) => (
  <div className={cx("flex", "flex-col", "animate-flicker")} style={style}>
    <div
      className={cx("relative", {
        "pb-img-horizontal": orientation === "horizontal",
        "pb-img-vertical": orientation === "vertical",
        "bg-gray-900": !style || !style.backgroundColor,
      })}
    />
    {!disableTitleSection && (
      <>
        <div
          className={cx("relative", "mt-2", "bg-gray-900", "pt-6", "max-w-80%")}
        />
        <div
          className={cx("relative", "mt-1", "bg-gray-900", "pt-4", "max-w-50%")}
        />
      </>
    )}
  </div>
);

export default CardSkeleton;
