import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

import { StandardProps } from "../utils/element-type";

import { Post } from "../utils/gq-types";

interface SocialHeaderProps
  extends StandardProps<React.HtmlHTMLAttributes<HTMLDivElement>> {
  post: Post;
  isSinglePost?: boolean;
}

interface SiteData {
  site: {
    siteMetadata: {
      siteUrl: string;
      social: {
        twitter: string;
      };
    };
  };
}

const SocialHeader: React.FunctionComponent<SocialHeaderProps> = ({
  post,
  isSinglePost = false,
}) => {
  const {
    site: {
      siteMetadata: {
        siteUrl,
        social: { twitter },
      },
    },
  } = useStaticQuery<SiteData>(graphql`
    query OGHeaderQuery {
      site {
        siteMetadata {
          siteUrl
          social {
            twitter
          }
        }
      }
    }
  `);
  return (
    <>
      <Helmet>
        {/* OpenGraph tags */}
        <meta property="og:url" content={`${siteUrl}`} />
        {isSinglePost ? <meta property="og:type" content="article" /> : null}
        <meta property="og:title" content={post.title} />
        {/* <meta property="og:description" content={"post"} /> */}
        <meta property="og:image" content={post.featureImage} />
        {/* <meta property="fb:app_id" content={seo.social.fbAppID} /> */}

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={twitter} />
        <meta name="twitter:title" content={post.title} />
        {/* <meta name="twitter:description" content={"description"} /> */}
        <meta name="twitter:image" content={post.featureImage} />
      </Helmet>
    </>
  );
};

export default SocialHeader;
